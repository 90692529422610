.testimonalHome {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/banner\ 05.png');
    width: 100%;
    height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonalHome h2 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 52px;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
}

/* google reviews */

.googleReview {
    padding: 20px 50px;
}

.googleLogo {
    margin: auto;
    width: 330px;
    height: 100px;
}

.googleLogo img {
    width: 100%;
    height: 100%;
}


@media(max-width: 600px) {
    .googleLogo {
        width: 100%;
        height: auto;
    }

    .googleReview {
        padding: 15px;
    }
}

/* practo */
.practoLogo {
    width: 260px;
    height: 102px;
    margin: auto;
}

.practoLogo img {
    width: 100%;
    height: 100%;
}

/* just dial */

.jdLogo {
    width: 250px;
    height: 70px;
    margin: auto;
}

.jdLogo img {
    width: 100%;
    height: 100%;
}

/* youtube  */

.youtubeLogo {
    width: 250px;
    height: 70px;
    margin: auto auto 30px auto;
}

.youtubeLogo img {
    width: 100%;
    height: 100%;
}

.youtubeFrame {
    width: 100%;
    height: 280px;
}

.youtubeFrame iframe {
    width: 100%;
    height: 100%;
}

.googleReviewCards .swiper {
    height: auto;
}

@media(max-width:430px) {
    .testimonalHome {
        background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/banner\ 05.png');
        height: 40vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        /* background-attachment: fixed; */
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}