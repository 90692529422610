.serviceHome {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/gal2.JPG');
    width: 100%;
    height: 550px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceHome h1 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 45px;
}

.serviceHome h6 {
    font-family: var(---paraFont);
    color: #fff;
    font-size: 22px;
}

@media(max-width: 600px) {
    .serviceHome {
        height: 450px;
    }

    .serviceHome h1 {
        text-align: center;
        margin-top: 30px;
    }

    .serviceHome h6 {
        text-align: center;
    }
}

@media(max-width:430px) {
    .serviceHome {
        height: 40vh;
    }

    .serviceHome h1 {
        margin: 0px;
        font-size: 35px;
    }
}