
.serviceCard {
    padding: 20px;
    background-color: #fff;
    height: 435px;
    border-radius: 5px;
    box-shadow: 4px 4px 8px #888;
}

.serviceCardImg {
    width: 100%;
    height: 280px;
    padding: 14px;  
}

.serviceCardText a {
    text-decoration: none;
}

.serviceCardText a:hover {
    text-decoration: underline;
}

.serviceCardText p {
    color: green;
    margin-top: 20px;
    font-weight: bold;
}

.serviceCardImg img {
    width: 100%;
    height: 100%;
    transition: 0.8s ease all;
    border-radius: 6px;
    box-shadow: 2px 2px 6px #888;
}

.serviceCardImg img:hover {
    transform: scale(1.06);
    transition: 0.8s ease all;
}

@media(max-width: 600px){
    .serviceCard {
        padding: 0px;
        height: auto;
    }
    .serviceCardImg {
        height: 240px;
    }
}
