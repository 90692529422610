.Publications-banner {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/award\ images/mam  Award.JPG');
    width: 100%;
    height: 500px;
    /* -webkit-background-size: cover; */
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Publications-banner h2 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 52px;
}


@media(max-width:430px) {
    .Publications-banner {
        height: 50vh;
    }
}

.Publications-Section-2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.Publications-Para {
    padding: 30px;
    border-radius: 10px;
    background-color: whitesmoke;
    box-shadow: 0 0 5px gray;
    margin: 30px 0px 0px 0px;
}

.Publications-Para h2{
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
}

.Publications-Para ul li {
    font-family: var(---paraFont);
    font-size: 16px;
    text-align: justify;
    margin: 0px 0px 20px 0px;
}

.Publications-Section-2-heading {
    /* border: 1px solid red; */
    padding: 0px 0px 30px 0px;
    text-align: center;
}

.Publications-Section-2-heading h3 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
}