html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.headerSection {
    /* position: fixed; */
    width: 100%;
    z-index: 1000;
}

.navbarScroll {
    position: fixed;
}

/* social icons header  */
.socialMeidaAndContact {
    padding: 5px 0;
    background-color: var(---primeColor);
}

.socialMeidaAndContact ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.socialMeidaAndContact .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.socialMeidaAndContact .headerContact a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.socialMeidaAndContact .headerSocial a {
    color: #fff;
    padding: 0px 6px;
    font-size: 18px;
}

/* navbar */
.navbar {
    background-color: rgb(255 255 255);
    /* position: absolute; */
    width: 100%;
}

.navbar .nav-item .nav-link {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    transition: .4s ease-in-out;
}

.navbar-collapse {
    justify-content: center;
}

.navbar-brand img {
    height: 70px;
    width: 110px;
}

.navbar-nav {
    width: 100%;
    justify-content: end;
}

.navbar-nav li a {
    list-style: none;
    text-decoration: none;
    margin: 5px;
    color: rgb(0 0 111);
    font-weight: 600;
    font-size: 18px;
}

.navbar-nav li span {
    list-style: none;
    text-decoration: none;
    margin: 5px;
    color: rgb(0, 179, 255);
    font-weight: 600;
    font-size: 18px;
}

.dropdown a {
    color: var(---primeColor);
}

.dropdown:hover a {
    color: var(---secondColor);
}

.nav-link:focus {
    color: var(---secondColor) !important;
}

.nav-link a {
    text-decoration: none;
    color: var(---primeColor);
    font-weight: 600;
}

.nav-link:hover a {
    color: var(---secondColor);
}

.nav-link:active {
    color: var(---secondColor);
}

.dropdown-menu a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 600;
}

.navbar-nav {
    justify-content: space-evenly !important;
}

.dropdown-menu {
    overflow-y: scroll;
    height: 50vh !important;
}

.dropdown-item a {
    display: block;
}


/*
 *  STYLE 1
 */

.show::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.show::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.show::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}




@media(max-width: 600px) {
    .navbar-brand img {
        height: 60px;
        width: 90px;
    }

    .aboutHome h6 {
        text-align: center;
    }
}

/* ============ dropdown menu ================ */

.navbar-nav .list-nav-item {
    position: relative;
}

.navbar-nav li.nav-item .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 20px 20px;
    top: 100%;
    transform: translateY(50px);
    left: 0;
    width: 250px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 3px rgb(0 0 0 / 30%);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1000;
    transition: 0.4s all;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    border-top: 3px solid var(---primeColor);
}

.navbar-nav li.nav-item .dropdown .sub-nav-link {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    color: #111;
    font-size: 1rem;
    font-family: var(---robotFont);
    text-transform: capitalize;
}

.navbar-nav li.nav-item:hover .dropdown {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}


/* ////////////////////////////// */

.dorpdown {
    position: absolute;
    background: #fff;
    color: #111;
    border-radius: 5px;
    width: 240px;
    top: 100px;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: 0.5s ease-in-out;
    height: 50vh;
    overflow-y: scroll;
}

.dropdown_list {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.dropdown_list a {
    color: #111;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 5px 10px;
    text-decoration: none;
    color: var(---primeColor)
}

.dropdown_list a:hover {
    color: var(---primaryColor);
}

.dropdownShow {
    background: unset;
    border: unset;
    text-align: left;
    font-weight: 600;
    text-decoration: none;
    color: var(---primeColor);
}

.dropdownShow:focus .dorpdown,
.dropdownShow:hover .dorpdown {
    top: 70px;
    visibility: visible;
    opacity: 1;
    z-index: 100;
    transition: 0.5s ease-in-out;
}

@media (max-width: 500px) {

    .dropdownShow:focus .dorpdown,
    .dropdownShow:hover .dorpdown {
        top: 180px;
        visibility: visible;
        opacity: 1;
        z-index: 100;
        transition: 0.5s ease-in-out;
    }

    .dropdownShow {
        padding: 0px;
    }

}

/* ////////////////////////////////////////////////// */

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(---primeColor);
}