.internationalHome {
    background: url('../../images/banner\ back.jpg');
    width: 100%;
    height: 480px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.eyelife-Logo {
    margin: 0px 0px 0px 20%;
    height: 25vh;
    width: 40vh;
}

.eyelife-Logo img {
    height: 100%;
    width: 100%;
}

.internationalHome h2 {
    font-family: var(---headFont);
    font-size: 40px;
    color: #fff;
}

/* details */

.pointersSection {
    padding: 20px 120px;
}

.pointersSection1 {
    padding: 20px 120px;
}

.pointersSection h2 {
    font-family: var(---headFont);
    text-align: center;
    font-size: 30px;
    color: #70bf43;
    margin-bottom: 30px;
}

.pointersSection1 h2 {
    color: var(---primeColor);
    font-size: 40px;
    padding-bottom: 10px;
    position: relative;
    text-align: left;
}

.pointersSection1 h2 {}

.pointersSection h6 {
    color: var(---primeColor);
    font-family: Lora, serif;
    font-size: 25px;
}

.pointersSection h6 a {
    text-decoration: none;
    color: var(---secondColor);
    font-size: 25px;
    font-family: var(---headFont);
}


.pointersSection1 h6 {
    color: var(---primeColor);
    font-family: Lora, serif;
    font-size: 25px;
    margin-bottom: 15px;
}

.pointersSection1 h6 span {
    font-family: Lora, serif;
    font-size: 25px;
    margin-right: 20px;
}

.pointersSection1 h6 a {
    text-decoration: none;
    color: var(---secondColor);
    font-size: 25px;
    font-family: var(---headFont);
}

.international-category {
    font-family: var(---headFont);
    color: var(---primeColor);
    font-size: 40px;
    padding-bottom: 10px;
    position: relative;
    text-align: left;
}

.international-category::before {
    background-color: #70bf43;
    bottom: 0;
    content: "";
    height: 3px;
    position: absolute;
    width: 75px;
}

.pointersSection p {
    font-family: var(---paraFont);
    font-size: 16px;
}

.pointersSection1 p {
    font-family: var(---paraFont);
    font-size: 16px;
}

.pointersSection p span {
    padding: 0px 10px;
}

@media(max-width: 600px) {
    .pointersSection {
        padding: 20px;
    }
    .eyelife-Logo {
        margin: 0px 0px 0px 0px;
        height: 18vh;
        width: 30vh;
    }
    .internationalHome {
        background:  linear-gradient(90deg, rgba(255, 255, 255,0.4), rgba(255, 255, 255,0.1)), url('../../images/banner\ back.jpg');
        width: 100%;
        height: 50vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .international-category {
        font-family: var(---headFont);
        color: var(---primeColor);
        font-size: 30px;
        padding-bottom: 10px;
        position: relative;
        text-align: left;
    }
    .pointersSection h6 a {
        text-decoration: none;
        color: var(---secondColor);
        font-size: 20px;
        font-family: var(---headFont);
    }
    .pointersSection1 h2 {
        color: var(---primeColor);
        font-size: 30px;
        padding-bottom: 10px;
        position: relative;
        text-align: left;
    }

    .pointersSection1 h6 a {
        text-decoration: none;
        color: var(---secondColor);
        font-size: 20px;
        font-family: var(---headFont);
    }

}