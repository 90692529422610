.reviewCard {
    margin: 20px;
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 2px 2px 6px #888;
}

.reviewCard h6 {
    height: 180px;
    overflow-y: scroll;
    padding: 6px;
}

.reviewCard h6::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.reviewCard h6::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.reviewCard h6::-webkit-scrollbar-thumb {
    background-color: #232323;
    border: 2px solid #c4c4c4;
}

.reviewNameImg {
    display: flex;
}

.reviewQuote i {
    font-size: 35px;
    text-align: left;
    margin-bottom: 10px;
}

.reviewStars i {
    margin: 0px 5px;
    color: gold;
    font-size: 20px;
}

.reviewImg {
    width: 90px;
    height: 80px;
    padding: 8px;
}

.reviewImg img {
    width: 100%;
    height: 100%;
}

.reviewNameStar {
    margin-left: 10px;
}

.reviewNameStar h4 {
    font-family: var(---headFont);
    text-transform: capitalize;
    color: var(---primeColor);
    
}

@media(max-width:600px) {
    .reviewCard {
        margin: 10px 0px;
    }

    .reviewImg {
        width: 60px;
        height: 60px;
    }
}