@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Abril+Fatface&family=Acme&family=Alumni+Sans+Collegiate+One&family=Amiri&family=Anton&family=Arvo:ital,wght@0,400;1,700&family=Baloo+Bhai+2&family=Bangers&family=Bebas+Neue&family=BhuTuka+Expanded+One&family=Ceviche+One&family=Cinzel&family=Comforter+Brush&family=Crimson+Text&family=EB+Garamond&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Fredericka+the+Great&family=Gentium+Book+Plus&family=Gentium+Plus&family=Heebo&family=Henny+Penny&family=Ibarra+Real+Nova&family=Inconsolata:wght@300&family=Josefin+Sans:wght@100&family=Kanit:wght@300&family=Kaushan+Script&family=Libre+Baskerville&family=Lobster&family=Lora&family=Merriweather:wght@300&family=Montserrat+Subrayada&family=Nanum+Myeongjo&family=Norican&family=Nunito:wght@200&family=Oleo+Script+Swash+Caps&family=Open+Sans:wght@500&family=Oswald:wght@300&family=Philosopher&family=Playball&family=Quicksand:wght@300&family=Raleway:wght@100&family=Roboto:wght@700&family=Rubik+Dirt&family=Rubik+Microbe&family=Rubik+Wet+Paint&family=Sancreek&family=Slabo+27px&family=Slackey&family=Source+Serif+Pro:ital,wght@1,600&family=Space+Mono:ital@1&family=Tiro+Kannada&family=Tiro+Tamil&family=Tiro+Telugu&family=Trade+Winds&family=Varela+Round&display=swap');

.gallerySection {
    background: linear-gradient(to right, rgb(0,0,0, .2), rgb(0,0,0, .3)),url('../../images/banner2.jpg') ;
    width: 100%;
    height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 115% 115%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gallerySection h2 {
    font-family: var(---headFont);
    font-size: 50px;
    color: #fff;
}

.Gallery-Container {
    padding: 30px 80px;
}

.galleryTagline h6{
    font-family: var(---paraFont);
    font-size: 22px;
    text-align: center;
}

.Gallery-Images {
    height: 45vh;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.Gallery-Images img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.popup-Image {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, .6);
    height: 100%;
    width: 100%;
    display: none;
}

.popup-Image span {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.popup-Image span svg {
    color: white;
    font-size: 4vh;

}

.popup-Image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    object-fit: cover;
}

.Modelling_Shoots {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Modelling_Shoots h3 {
    color: white;
    font-family: 'Sancreek', cursive;
    margin: 10px;
    padding: 20px;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

@media(max-width:600px) {
    .Gallery-Container{
        padding: 20px;
    }
}

@media(max-width:430px){
    .gallerySection {
        height: 40vh;
    }
}