.procedureHome {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/banner\ 001.JPG');
    width: 100%;
    height: 480px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 115% 115%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.procedureHome h1 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 50px;
    margin-top: 30px;
}

.procedureList {
    /* padding: 20px 60px; */
}

.ProcedureImage01 {
    width: 200px !important;
    height: 300px !important;
    margin-bottom: 20px;
}

.ProcedureImage02 {
    width: 300px !important;
    height: 300px !important;
    margin-bottom: 20px;
}

@media(max-width: 500px) {
    .procedureHome {
        height: 350px;
    }

    .procedureHome h1 {
        margin-top: 60px;
    }

    .procedureList {
        padding: 15px;
    }
}

@media(max-width:430px) {

    .procedureHome {
        height: 50vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .procedureHome h1 {
        margin-top: 0px;
        font-size: 30px;
    }
}