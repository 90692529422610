
.topBtnSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-btn {
    font-size: 2.4rem;
   padding: 0px 17px 2px 17px;
    color: #fff;
    background-color: #0171bd;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 40%) 0px 5px 10px;
    position: fixed;
    bottom: 4rem;
    right: 3rem;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@keyframes gototop {
    0% {
        transform: translateY(-0.5rem);
    }
    100% {
        transform: translateY(1rem);
    }
}