.aboutHome {
    background: linear-gradient(to right, rgb(0,0,0, .2), rgb(0,0,0, .3)),url('../../images/aboutBanner.JPG') ;
    width: 100%;
    height: 600px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.aboutHome h1 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 45px;
}

.aboutHome h6 {
    font-family: var(---paraFont);
    color: #fff;
    font-size: 22px;
}

@media(max-width: 600px){
    .aboutHome {
        height: 400px;
        background-attachment: unset;
    }
}
/* =============== our story ========================== */

.ourStory {
    padding: 20px 100px;
}

.ourStoryImg  {
    width: 80%;
    height: 400px;
}

.ourStoryImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 4px 4px 8px #888;
}

.ourStoryText h2{
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
}

.ourStoryText h6 {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
}

/* ====================== mission ===================== */

.missionSection {
    padding: 20px 100px;
}

.missionText h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
}

.missionText p {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
}

.missionImg {
    width: 80%;
    height: 400px;
}

.missionImg img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 4px 4px 8px #888;
}

/* =================== why us ========================== */

.whyChooseUS {
    padding: 40px 100px;
}

.whyChooseUS .titleSection::after {
    height: 7rem;
}

.whyChooseImg {
    width: 460px;
    height: 350px;
}

.whyChooseImg img {
    width: 100%;
    height: 100%;
}

.whyChooseUS .titleSection h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
}

.whyChooseUS .titleSection p {
    font-family: var(---headFont);
    text-align: left;
    margin-bottom: 2.8rem;
    color: var(---secondColor);
}

.whyUsCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.whyUsIcon span {
    font-size: 3rem;
    color: var(---secondColor);
}

.whyUsTitle h3 {
    font-size: 1.2rem;
    color:var(---primeColor);
    text-transform: capitalize;
    font-weight: 500;
    font-family: var(---headFont);
}

.whyUsText p {
    color: #444;
    font-size: .85rem;
    text-align: center;
    font-family: var(---paraFont);
    max-width: 250px;
}

@media(max-width: 800px) {
    .whyUsIcon span {
        font-size: 2rem;
        color: var(---primeColor);
    }
    .ourStory{
        padding: 20px;
    }
    .ourStoryImg {
        width: 100%;
        height: 300px;
    }
    .missionSection{
        padding: 20px;
    }
    .missionImg{
        width: 100%;
        height: 300px;
    }
    .whyChooseUS {
        padding: 20px;
    }
    .whyChooseUS .titleSection h2 {
        font-size: 35px;
    }
}

@media(max-width:430px){
    .aboutHome {
        height: 40vh;
        background-attachment: unset;
    }
}