.contact {
    padding: 20px 100px;
}

.contact .info {
    border-top: 3px solid var(---secondColor);
    border-bottom: 3px solid var(---secondColor);
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.titleSection h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}

.contact .titleSection::after {
    height: 7rem;
}

.contact .titleSection p {
    text-align: left;
    font-size: .90rem;
}

.contact .info i {
    font-size: 20px;
    color: var(---secondColor);
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(---primeColor);
    font-family: var(---headFont);
}

.contact .info a {
    text-decoration: none;
}

.contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #808080;
}

.addres2 {
    padding: 0 0 0 60px;
}

.socialList ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 20px 0 0 50px;
}

.socialList ul li a i {
    margin: 0 5px;
    /* background: transparent !important; */
    background-color: #0d6efd;
    border-radius: 50px !important;
    color: var(---secondColor) !important;
    /* border: 2px solid var(---secondColor); */
}

.ContactIcons {
    /* border: 1px solid red; */
}

.contact .info span {
    margin-bottom: 20px;
    font-size: 16px;
    color: #808080;
}

.contact .info .email p {
    padding-top: 5px;
}

.email p span a {
    text-decoration: none;
    color: #808080;
}

.contact .info .social-links {
    padding-left: 60px;
}

.contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
}

.contact .info .social-links a:hover {
    /* background: var(---secondColor); */
    /* color: #fff; */
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    /* background: var(---secondColor); */
    /* color: #fff; */
}

.contact .php-email-form {
    width: 100%;
    border-top: 3px solid var(---secondColor);
    border-bottom: 3px solid var(---secondColor);
    padding: 10px 30px 30px 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
    margin-bottom: 10px;
}

.contact .php-email-form label {
    padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: var(---secondColor);
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
    background: var(---primeColor);
    border: 0;
    padding: 12px 34px;
    transition: 0.4s;
    border-radius: 50px;
    color: white;
}

.contact .php-email-form button[type="submit"]:hover {
    /* background: #209dd8; */
}

.FormHeading h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(---primeColor);
    font-family: var(---headFont);
    margin: 0px 0px 20px 0px;
    text-transform: capitalize;
}

@media(max-width: 600px) {
    .contact {
        padding: 12px;
    }

    .titleSection h2 {
        text-align: center;
        color: var(---primeColor);
        font-size: 30px;
    }

    .contact .info p {
        padding: 0 0 0px 60px;
        margin-bottom: 10px;
    }
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}