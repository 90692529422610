.footerSection {
  width: 100%;
  display: block;
  background: whitesmoke;
  padding: 20px 0;
}



.logo {
  width: 131px;
  height: 85px;
  margin: 20px 0;
}

.inner-footer {
  width: 95%;
  margin: auto;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.footer-items {
  width: 25%;
  padding: 10px 20px;
  box-sizing: border-box;
  color: green;
}

.comapnyDetail p {
  font-size: 14px;
  /* text-align: justify; */
  line-height: 25px;
  /* color: #fff; */
  text-transform: capitalize;
}


/* 
  .footer-items h1 {
  color:green;
  } */

.quickLink {
  margin-top: 10px;
  text-align: center;
}

.quickLink h2 {
  /* color: #fff; */
  font-size: 1.6rem;
}

.quickLink i {
  color: #C0C0C0;
  font-size: 16px;
  text-align: justify;
  /* line-height: 25px; */
  color: grey;
  padding: 2px;
}

.quickLink p {
  /* color: #fff; */
  font-size: 1.2rem;
  padding: 2px;
}

.quickLink ul {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}

.quickLink ul li {
  margin: 10px 0;
}

.quickLink a {
  text-decoration: none;
  /* color: #fff; */
}

.footer-bottom {
  padding: 10px;
  background: #00121b;
  /* color: #fff; */
  font-size: 12px;
  text-align: center;
}

.Footer-Item {
  text-align: left;
  font-size: 15px;
  margin: 5px 0px 5px 0px;
  color: black;
}

.Footer-Item a {
  color: var(---primeColor);
}

.Footer-Item:hover a{
  color: var(---secondColor) !important;
}

@media(max-width:430px) {
  .quickLink a {
    font-size: 15px !important;
  }

}

@media(min-width:500px) {
  .footerSection {
    width: 100%;
    display: block;
    padding: 50px 0;
  }
}

/* for tablet mode view */

@media screen and (max-width: 1275px) {
  .footer-items {
    width: 50%;
  }
}


/* for mobile screen view */

@media screen and (max-width: 660px) {
  .footer-items {
    width: 100%;
  }
}

@media(max-width:430px) {
  .quickLink a {
    font-size: 15px !important;
  }
}

@media(max-width:768px) {
  .footerSection {
    width: 100%;
    display: block;
    padding: 20px 0;
    padding: 0px 0px 50px 0px;
  }
}