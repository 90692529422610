.down_enquiry {
    background-color: var(---primeColor) !important;
    bottom: 0px;
    position: fixed;
    display: none;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    padding: 6px;
}

p.enq {
    display: none;
}

p.enq1 {
    display: none;
}

@media only screen and (max-width:640px) and (min-width:320px) {

    .down_enquiry {

        display: block !important;

    }

    .down_enquiry a {

        line-height: .5;

        color: #fff;

        display: block;

        font-size: 14px;

        text-decoration: none;

        padding: 0px 6px 3px;

        text-align: center;

    }

    p.enq {

        width: 33%;

        display: inline-block;

        border-right: 1px solid #fff;

    }

    p.enq .fa-whatsapp {

        color: #fff;

        font-size: 17px;

    }

    p.enq1 {

        width: 33%;

        display: inline-block;

        margin-left: 2px;
        
        border-right: 1px solid #fff;

    }

    p.enq2{
        width: 33%;

        display: inline-block;

        margin-left: 1px;

        text-align: right;
        
    }

}