@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root {
    ---headFont: 'Roboto', sans-serif;
    ---paraFont: 'Lato', sans-serif;
    ---primeColor: rgb(23 104 183);
    ---secondColor: rgb(112 191 67);
}   

html, body {
    overflow-x: hidden;
}