/* ====================== Home section ====================== */

.homeSection {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    background-color: var(---bgGrey);
}

.homeSection .carousel-inner,
.homeSection .carousel-item {
    width: 100%;
    height: 70vh;
}

.swiper-wrapper {
    z-index: 0 !important;
}

.swiper {
    z-index: 0 !important;
}

.firstSlide {
    position: relative;
    width: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.1)), url("../../images/banner1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 100vh;
}

/* .secondSlide {
    position: relative;
    z-index: -1;
    width: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1)), url("../../images/SBanner.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-repeat: repeat;
    background-position: center;
    height: 70vh;
} */

.thirdSlide {
    position: relative;
    width: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1)), url("../../images/banner\ 02.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-position: center;
    height: 70vh;
}

.swiper {
    height: 70vh;
}

.HomeBanner-Btn {
    position: absolute;
    top: 3%;
    left: 1%;
}


.HomeBanner-Btn button {
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #0171bd;
    color: white;
    text-transform: capitalize;
    font-weight: 600;
}

.swiper-button-prev {
    display: none !important;
}

.swiper-button-next {
    display: none !important;
}

@keyframes shrink {
    0% {
        background-size: 115% 115%;
    }

    100% {
        background-size: 100% 100%;
    }
}

@media (max-width: 1045px) {
    #home {
        padding: 0px;
        margin: 0px;
        height: 68vh;
    }

    .homeContent h1 {
        font-size: 30px;
        text-align: center;
        padding: 15px 5px;
        text-align: center;
        color: #e30d0d;
        font-family: var(---headFont);
    }

    .homeContent h6 {
        font-size: 15px;
        padding: 15px 5px;
        text-align: center;
        font-family: var(---paraFont);
    }

    .homeContent {
        text-align: center;
        padding-top: 50px;
    }
}

.homeContent .homeContentLogo img {
    width: 100%;
    height: 100%;
}

.homeContent h6 {
    padding-top: 10px;
    color: #f09433;
    padding-left: 25px;
    font-family: var(---paraFont);
}

.homeContent button {
    padding: 12px 15px;
    color: #111;
    font-family: var(---paraFont);
    background: transparent;
    border-radius: 15px;
    font-size: larger;
    border: #111 solid 2px;
}

.homeContent h1 {
    color: #111;
    font-family: var(---headFont);
    font-size: 120px;
    padding: 20px 0px 20px 25px;
}


.navbar-socialLinks ul {
    list-style: none;
    display: flex;
    padding: 0;
}

.navbar-socialLinks ul li a {
    margin: 0 7px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.videobtn {
    display: flex;
    text-align: center;
}

@media(max-width:500px) {
    .homeSection {
        height: 50vh;
    }

    .firstSlide {
        height: 50vh;
    }

    .secondSlide {
        height: 50vh;
    }

    .thirdSlide {
        height: 50vh;
    }

    .homeSection .carousel-inner,
    .homeSection .carousel-item {
        width: 100%;
        height: 50vh;
        z-index: -1;
    }

    .homeSection.swiper-slide {
        height: 50vh;
    }


    .homeContent {
        margin: 60px 20px 20px 20px;
        padding: 20px;
    }

    .homeContent h6 {
        padding-top: 25px;
        color: #111;
        padding-left: 0px;
        font-family: var(---paraFont);
    }

    .homeContent h1 {
        color: #111;
        font-family: var(---headFont);
        font-size: 45px;
        padding-top: 0px;
        padding-left: 0px;
    }

    .homeContent h5 {
        padding-top: 10px;
        color: #111;
        padding-left: 0px;
        font-family: var(---paraFont);
    }

    .homeContent button {
        padding: 8px 12px;
        color: #111;
        font-family: var(---paraFont);
        background: transparent;
        border-radius: 15px;
        font-size: 18px;
        border: #111 solid 2px;
    }

    .homeContent .homeContentLogo {
        width: 200px;
        height: 160px;
        padding: 0px;
    }

    #firstSlide {
        z-index: -1;
        width: 100%;
        background: linear-gradient(to right, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.1)), url("../../images/banner1-phone.jpg");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        height: 50vh;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: unset;
        background-position: unset;
    }
}

@media(max-width:430px) {
    .firstSlide {
        height: 40vh;
    }

    .secondSlide {
        height: 40vh;
    }

    .thirdSlide {
        height: 40vh;
    }

}

/* ======================== about section ====================== */

.aboutPageSection {
    padding: 50px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}


.aboutPageSection .col-md-6 {
    min-height: 450px;
}

.aboutPageImg {
    width: 500px;
    height: 350px;
}

.aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 400px;
    transform: translate(40px, 40px);
    border-top: 30px solid #fff;
    border-left: 30px solid #fff;
    border-radius: 10px;
}

.aboutPageText {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.aboutPageText h5 {
    color: var(---secondColor);
    font-size: 1rem;
    font-weight: 500;
    font-family: var(---headFont);
    text-transform: capitalize;
}

.aboutPageText h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}

.aboutPageText p {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
}

.aboutPageText ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutPageText ul li {
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border: 2px solid var(---primeColor);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutPageText ul li a {
    color: var(---primeColor);
    font-size: 1rem;
}

@media(max-width:990px) {
    .aboutPageImg img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 350px;
        transform: translate(25px, 25px);
        border-top: 30px solid #fff;
        border-left: 30px solid #fff;
        border-radius: 10px;
    }

    .aboutPageImg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 300px;
    }

    .aboutPageText h2 {
        font-size: 2.2rem;
    }

    .aboutPageSection .col-md-6 {
        min-height: unset;
    }

    .aboutPageImg {
        padding: 0 20px 0 0;
    }
}

@media(max-width:767px) {
    .aboutPageSection .col-md-6 {
        min-height: 350px;
    }
}

@media(max-width:500px) {
    .aboutPageText h2 {
        font-size: 2rem;
    }

    .aboutPageSection {
        padding: 20px 0 40px 0;
    }

    .aboutPageSection .col-md-6 {
        min-height: unset;
    }

    .aboutPageImg img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 300px;
        transform: translate(20px, 20px);
        border-top: 15px solid #fff;
        border-left: 15px solid #fff;
        border-radius: 10px;
    }
}

/* ==================== service section ==================== */

.serviceSection {
    padding: 20px 60px;
    /* background: linear-gradient(to right, rgb(0,0,0, .2), rgb(0,0,0, .1)), url(../../images/serviceBg.jpg); */
    background-color: #eee;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.serviceSection .ServiceSectionsCards a {
    color: #111;
}

.serviceSectionHead {
    text-align: center;
}

.serviceSectionHead h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}


.serviceCardText h2 {
    font-size: 27px;
    margin-bottom: 10px;
    font-family: var(---headFont);
    text-transform: capitalize;
    color: var(---primeColor);
}

.serviceCardText {
    padding: 5px 20px;
    /* height: 130px; */
}

.serviceCardText h5 {
    font-family: var(---headFont);
}

.serviceCardText h6 {
    font-family: var(---paraFont);
    font-size: 15px;
    font-weight: 500;
}


.serviceCard .serviceCardText h5 {
    color: var(---secondColor);
    font-size: 16px;
    font-weight: bold;
}

.serviceSectionInner a {
    text-decoration: none;
}

.moreServices {
    background: var(---primeColor);
    color: #fff;
    border-radius: 12px;
    padding: 10px 12px;
    font-weight: bold;
    border: #eee solid 1px;
}

.moreServices button {
    background: transparent;
    border: none;
    font-family: var(---headFont);
    color: #fff;
    font-size: 22px;
}

@media(max-width: 600px) {
    .serviceSection {
        padding: 15px;
    }

    .serviceSectionHead h2 {
        font-size: 30px;
        margin-bottom: 5px;
    }
}

/* ================= team ===================== */

.teamSecrtion {
    padding: 20px;
}

.teamSecrtion .teamHead {
    text-align: center;
    margin-bottom: 30px;
}



.teamHead h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}

.teamHead h6 {
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
    color: var(---secondColor);
}

.teamCard {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
}

.teamCard .teamCardImg {
    width: 310px;
    height: 300px;
    padding: 20px;
}

.teamCard .teamCardImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 2px 2px 6px #888;
}

.teamCardText h4 {
    font-family: var(---headFont);
    font-size: 25px;
    color: var(---primeColor);
}

.teamCardText a p {
    display: inline-block;
    padding: 6px 20px;
    border-radius: 50px;
    margin: 10px 0px 0px 0px;
    background-color: var(---primeColor);
    color: white;
}

.teamCardText h6 {
    font-size: 15px;
    font-weight: normal;
    font-family: var(---paraFont);
}

.teamCardText a {
    font-family: var(---paraFont);
    color: #111;
    text-decoration: none;
}

@media(max-width: 600px) {
    .teamSecrtion {
        padding: 15px;
    }
}

/* =================== testimonal========================== */

.testimonalHead {
    padding: 20px;
    text-align: center;
}

.testimonalHead h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}

.testimonalHead h6 {
    font-family: var(---paraFont);
    font-size: 20px;
}

.youTubeRating {
    padding: 20px 80px;
}

.youtubeFrame {
    width: 100%;
    height: 280px;
}

.youtubeFrame iframe {
    width: 100%;
    height: 100%;
}

.testimonalSection {
    padding: 20px 40px;
}

.testimonalTwoBox {
    display: flex;
    padding: 0px 30px;
}

.googleTestimonal {
    background-color: #eee;
}

.googleTestimonal .googleTestimonalLogo {
    width: 80%;
    height: 120px;
}

.googleTestimonal .googleTestimonalLogo img {
    width: 100%;
    height: 100%;
}

.practoHomeLogo {
    height: 90px !important;
}

@media(max-width: 600px) {
    .youTubeRating {
        padding: 20px;
    }

    .testimonalSection {
        padding: 0px;
    }

    .googleTestimonal {
        padding: 10px;
    }

    .googleTestimonal .googleTestimonalLogo {
        margin-top: 25px;
    }

    .practoHomeLogo {
        height: 70px !important;
    }

}

@media(max-width:430px) {
    @media (max-width: 600px) {}

    .googleTestimonal .googleTestimonalLogo {
        margin-top: 0px;
    }
}