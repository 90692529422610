.conditionsHome {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/aboutBanner.JPG');
    width: 100%;
    height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 115% 115%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.conditionsHome h2 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 45px;
}

.conditionsHome h6 {
    font-family: var(---paraFont);
    color: #fff;
    font-size: 22px;
}

/* ================= condition details =================== */

.conditionSection {
    padding: 0px 70px;
}

.conditionText h2 {
    font-family: var(---headFont);
}

.conditionImage-con{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.conditionImage {
    height: 40vh;
    width: 55vh;
}

.conditionImage img{
    height: 100%;
    width: 100%;
}
.conditionText p {
    font-family: var(---paraFont);
    font-size: 18px;
}

.conditionSection .conditionImg {
    width: 80%;
    height: 100%;
}

.conditionSection .conditionImg img {
    width: 100%;
    height: 100%;
}

/* condition quesitons */

.conditionQuestions {
    padding: 20px 70px;
}

.conditionQuestions .conditionQuestionsText h5 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 22px;
}

.conditionQuestions .conditionQuestionsText p {
    font-family: var(---paraFont);
    font-size: 18px;
}

/* ////////////////////// Points /////////////////// */

.Point-Con {
    padding: 0px 70px;
}

.Point-Con h4 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 22px;
}

.Point-Con p {
    font-family: var(---paraFont);
    font-size: 18px;
}

.Condition-Last-Para p{
    font-family: var(---paraFont);
    font-size: 18px;
}



@media(max-width: 600px) {
    .conditionSection {
        padding: 20px;
    }

    .conditionQuestions {
        padding: 20px;
    }
    .pointersSection1 {
        padding: 20px !important;
    }
}

@media(max-width:430px) {
    .conditionsHome {
        height: 300px;
        background-size: 100% 60%;
    }

    .conditionImage {
        height: 40vh;
        width: 100%;
    }
}