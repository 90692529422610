.procedureCard {
    padding: 20px;
}

.procedureCardImg{
    /* width: 250px;
    height: 200px; */
    /* margin-bottom: 20px; */
}

/* .procedureCardImg img{
    width: 100%;
    height: 100%;
} */

.procedureCardText h2{
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
}

.procedureCardText p{
    font-family: var(---paraFont);
}
