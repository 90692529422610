.serviceDetailHome {
    background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .3)), url('../../images/gal8.JPG');
    width: 100%;
    height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 115% 115%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.serviceDetailHome h2 {
    text-transform: capitalize;
    font-family: var(---headFont);
    color: #fff;
    font-size: 52px;
}

/* ============ details ================== */

.serviceDetailSection {
    padding: 30px 76px 0px 76px;
}

.serviceDetailSection .detailText {
    margin-top: 20px;
}

.serviceDetailSection .detailText h1 {
    font-family: var(---headFont);
    color: var(---primeColor);
}

.serviceDetailSection .detailText p {
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: justify;
}

.serviceDetailSection  .detailText h6{
    font-size: 20px;
    font-family: var(---headFont);
    color: var(---secondColor);
}

.Service-Detials-Para-con p {
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: justify;
}

.Service-Detials-Para-con h6{
    font-size: 20px;
    font-family: var(---headFont);
    color: var(---secondColor);
}

.Service-Point-con {}

.Service-Point-con h5 {
    color: var(---secondColor);
    font-size: 20px;
    font-weight: 500;
    font-family: var(---headFont);
    text-transform: capitalize;
}

.Service-Point-con p {
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: justify;
}

.Service-Para-Point-con {}

.Service-Para-Point-con h6 {
    color: var(---secondColor);
    font-size: 20px;
    font-weight: 500;
    font-family: var(---headFont);
    text-transform: capitalize;
}

.Service-Para-Point-con p {
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: justify;
}

.Service-Image-Con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailImg {
    width: 400px;
    height: 310px;
    /* border: 1px solid red; */
}

.detailImg .swiper {
    height: 260px !important;
}

.Service-Image01 {
    /* border: 1px solid blue; */
    /* height: 100%; */
}

.Service-Image01 img {
    width: 100%;
    height: 100%;
}

@media(max-width:600px) {
    .serviceDetailSection {
        padding: 20px;
    }
}

/* ======= symptoms ================ */

.symptoms {
    padding: 20px 120px;
}

.symptoms .symptomsHead h3 {
    font-family: var(---headFont);
    font-size: 35px;
    margin-bottom: 20px;
}

.symptoms .symptomsList ul li {
    list-style: none;
    font-family: var(---paraFont);
    font-size: 20px;
}

.symptoms .symptomsList span {
    padding: 0px 15px;
}

.symptoms .symptomsList ul li:hover {
    color: var(---primeColor);
}

@media(max-width: 600px) {
    .symptoms {
        padding: 20px;
    }

    .detailImg {
        width: 100%;
        height: 300px;
    }
}

/* ========================= FAQ Section =========================== */


.faqsectionh {
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #efefef;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 80px 0px 0px 0px;
    margin-top: 25px;
}

.faqsectionh .faqsection {
    padding: 3rem 2rem;
}

.faqsectionh .faqsection .faqheader {
    padding-bottom: 20px;
}

.faqsectionh .faqsection .faqheader h2 {
    color: #192431;
    font-family: var(---robotFont);
    font-size: 29px;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 40px;
}

.accordion-header {
    font-family: var(---headFont);
    font-size: 22px;
}

.accordion-item .accordion-body {
    font-family: var(---paraFont);
}

.accordion-button::after {
    background: none;
    font-family: "Material Icons";
    content: '+';
}

.accordion-button:not(.collapsed)::after {
    background: none;
    font-family: "Material Icons";
    content: '-';
}

.accordion-item {
    background-color: white;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    box-shadow: 0px 5px 7px grey;
    font-family: poppins;
    margin: 10px 0;
}

.accordion-button:focus {
    z-index: 3;
    border-color: grey;
    outline: 0;
}

.faqimage {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
}

.faqimage img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.faqimage span {
    color: white;
    position: absolute;
    top: 36%;
    font-size: 85px;
    left: 45%;
}

/* servie video */

.serviceVideo {
    padding: 20px 80px;
}

.videoFrame {
    width: 100%;
    height: 450px;
}

.videoFrame iframe {
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.videoText {
    padding: 30px;
}

.videoText h4 {
    font-family: var(---headFont);
    font-size: 35px;
}

.videoText h6 {
    font-family: var(---paraFont);
    font-size: 18px;
    padding: 10px 0px;
}

.ServiceDeatilsbtn {
    /* border: 1px solid red; */
    padding: 5vh 0px;

}

.ServiceDeatilsbtn button {
    /* border: 1px solid greenyellow; */
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: var(---headFont);
    background-color: #0d6efd;
    color: white;
}

@media(max-width:768px) {
    .serviceDetailHome h2 {
        font-size: 30px;
        margin: 0px 0px 0px 10px;
    }
}


@media(max-width:500px) {
    .faqsectionh {
        margin-top: 0;
        padding: 0;
    }

    .faqsectionh .faqsection {
        padding: 0;
    }

    .serviceVideo {
        padding: 15px;
    }

    .videoFrame iframe {
        padding: 0px;
        border-radius: 10px;
    }
}


@media(max-width:430px) {
    .serviceDetailHome {
        height: 350px;
    }
}