/* =========== contact Home ========================== */
.contactHome {
    background: linear-gradient(to right, rgb(0,0,0, .2), rgb(0,0,0, .3)),url('../../images/Contact-Us.png') ;
    width: 100%;
    height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.contactHome h1 {
    font-family: var(---headFont);
    color: #fff;
    font-size: 45px;
}

.contactHome h6 {
    font-family: var(---paraFont);
    color: #fff;
    font-size: 22px;
}

/* =============== contact boxes ================= */

.contactBoxes {
    padding: 30px 80px;
    background-color:#eee ;
}

.contactBox {
    flex-direction: column;
    padding: 40px 40px;
    background-color: #fff;
    box-shadow: 4px 4px 8px #888;
    text-align: center;
    height: 100%;
}

.contactBox .contactBoxIcon {
    font-size: 30px;
    margin-bottom: 20px;
}

.contactBox .contactBoxIcon i {
    padding: 20px 28px;
    background-color: #e7f5fb;
    color: var(---secondColor);
    border-radius: 50%;
}

.contactBoxText a{
    text-decoration: none;
    color:var(---secondColor) ;
    display: block;
}

.contactBox .contactBoxText h4 {
    font-family: var(---headFont);
    color: var(---primeColor);
    text-transform: capitalize;
}

.contactBox .contactBoxText p {
    font-family: var(---paraFont);
    font-size: 17px;
    color: var(---secondColor);
    margin: 0px;
}

/* ======== form ============ */

.contactFrom {
    background: linear-gradient(to right, rgb(0,0,0, .3), rgb(0,0,0, .4)),url(../../images/formBg.jpg);
    padding: 40px 140px;
}

.info {
    border-top: 3px solid var(---secondColor);
    border-bottom: 3px solid var(---secondColor);
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.titleSection::after {
    height: 7rem;
}

.titleSection p {
    text-align: left;
    font-size: .90rem;
}

.info i {
    font-size: 20px;
    color: var(---secondColor);
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #111;
}

.info a {
    text-decoration: none;
}

.info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #808080;
}

.addres2 {
    padding: 0 0 0 60px;
}

.socialList ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 20px 0 0 50px;
}

.socialList ul li a i {
    margin: 0 5px;
    /* background: transparent !important; */
    border-radius: 50px !important;
    color: var(---secondColor) !important;
    /* border: 2px solid var(---secondColor); */
}

.contact .info span {
    margin-bottom: 20px;
    font-size: 16px;
    color: #808080;
}

.info .email p {
    padding-top: 5px;
}

.info .social-links {
    padding-left: 60px;
}

.info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
}

.info .social-links a:hover {
    /* background: var(---secondColor); */
    color: #fff;
}

.info .email:hover i,
.info .address:hover i,
.info .phone:hover i {
    /* background: var(---secondColor); */
    /* color: #fff; */
}

.php-email-form {
    width: 100%;
    border-top: 3px solid var(---secondColor);
    border-bottom: 3px solid var(---secondColor);
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

 .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

 .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

 .php-email-form .error-message br+br {
    margin-top: 25px;
}

 .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.php-email-form .form-group {
    margin-bottom: 10px;
}

.php-email-form label {
    padding-bottom: 8px;
}

.php-email-form input,
 .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

 .php-email-form input:focus,
.php-email-form textarea:focus {
    border-color: var(---secondColor);
}

 .php-email-form input {
    height: 44px;
}

 .php-email-form textarea {
    padding: 10px 12px;
}

 .php-email-form button[type="submit"] {
    border: 0;
    padding: 12px 34px;
    transition: 0.4s;
    border-radius: 50px;
    background-color: var(---primeColor);
    color: white;
}

.php-email-form button[type="submit"]:hover {
    /* background: var(---secondColor); */
}

.contactMap {
    width: 100%;
    height: 430px;
    padding: 30px 60px;
}

.contactMap iframe {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 40%) 0px 5px 10px;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media(max-width:600px) {
    .contactBoxes{
        padding: 20px;
    }
    .contactBox {
        margin-bottom: 10px;
    }
    .contactFrom {
        padding: 20px;
    }
}

@media(max-width: 600px) {
    .contactMap {
        padding: 20px;
    }
}

@media(max-width:430px){
    .contactHome {
        height: 40vh;
    }

    .contactHome h1 {
        font-size: 30px;
    }
}