.doctorHome {
    background: linear-gradient(to right, rgb(1, 113, 189, .5), rgb(1, 113, 189, .7)), url(../../images/banner2.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 360px;
    color: #fff;
    display: flex;
    align-items: center;
}

.doctorHome h1 {
    font-family: var(---headFont);
    margin: 70px;
    font-size: 40px;
}

.doctorDetail-con {
    padding: 20px 0px;
}

.doctorImage {
    height: 40vh;
    width: 40vh;
    border-radius: 10px;
    margin: 0px 0px 20px 0px;
}

.doctorImage img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.doctorText h2 {
    color: var(---primeColor);
    font-family: var(---headFont);
    font-size: 40px;
    font-weight: 500;
}

.doctorText h6 {
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
}

.doctorText h5 {
    color: black;
    font-size: 25px;
    font-weight: 500;
    font-family: var(---headFont);
    text-transform: capitalize;
    margin: 20px 0px;
}

.doctorText h4 {
    color: var(---secondColor);
    font-size: 20px;
    font-weight: 500;
    font-family: var(---headFont);
    text-transform: capitalize;
    margin: 20px 0px 0px 0px;
}

.doctorText ul li {
    font-family: var(---paraFont);
}

.doctorText p{
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    font-family: var(---paraFont);
}

@media(max-width: 600px) {
    .doctorDetail {
        padding: 20px;
    }

    .doctorImage {
        width: 100%;
        height: 280px;
    }

    .doctorText h2 {
        font-size: 30px;
        text-align: center;
    }
}